<template>
    <form class="partner-form">
        <LineItem
            v-if="partner"
            class="row no-gutters"
        >
            <div class="col-12">
                <FormInput
                    v-model="partner.partnerName"
                    class="large block-xs--xs"
                    label="Partner Name"
                    spellcheck="true"
                    required="true"
                    labelSize="partner-form"
                />
                <FormInput
                    v-model="partner.partnerShortCode"
                    class="large block-xs--xs"
                    label="Partner Shortcode"
                    required="true"
                    :disabled="partner.partnerShortCode != ''"
                    labelSize="partner-form"
                />
                <SelectInput
                    v-model="partner.partnerAssignment"
                    :options="partnerOptions"
                    class="block-xs--xs"
                    label="Partner Assignment"
                    required="true"
                    labelSize="partner-form" 
                    excludeBlank="true"
                />
                <FormInput
                    v-model="partner.companyNumber"
                    class="large block-xs--xs"
                    label="Company Number"
                    labelSize="partner-form"
                    :constrainingFunc="numberConstrainingFunc"
                />
                <FormInput
                    v-model="partner.vat"
                    class="large block-xs--xs"
                    label="VAT #"
                    labelSize="partner-form"
                    :constrainingFunc="alphanumericConstrainingFunc"
                />
            </div>
        </LineItem>
        <LineItem
            v-if="partner"
            class="row no-gutters"
        >
            <div class="col-12">
                <FormInput
                    v-model="partner.addressLine1"
                    class="large block-xs--xs"
                    label="Address Line 1"
                    spellcheck="true"
                    required="true"
                    labelSize="partner-form"
                    :constrainingFunc="alphanumericConstrainingFunc"
                />
                <FormInput
                    v-model="partner.addressLine2"
                    class="large block-xs--xs"
                    label="Address Line 2"
                    labelSize="partner-form"
                    :constrainingFunc="alphanumericConstrainingFunc"
                />
                <FormInput
                    v-model="partner.city"
                    class="large block-xs--xs"
                    label="City"
                    spellcheck="true"
                    required="true"
                    labelSize="partner-form"
                    :constrainingFunc="alphanumericConstrainingFunc"
                />
                <FormInput
                    v-model="partner.stateProvinceRegion"
                    class="large block-xs--xs"
                    label="State/Province/Region"
                    spellcheck="true"
                    required="true"
                    labelSize="partner-form"
                    :constrainingFunc="alphanumericConstrainingFunc"
                />
                <DropdownFormInput
                    v-model="country"
                    required="true"
                    class="large block-xs--xs block-xs--xs-bottom"
                    label="Country"
                    :options="countryOptions"
                    labelSize="partner-form"
                />
            </div>
        </LineItem>
        <LineItem
            v-if="partner"
            class="row no-gutters"
        >
            <div class="col-12">
                <FormInput
                    v-model="partner.commercialContact"
                    class="large block-xs--xs"
                    label="Commercial Contact"
                    labelSize="partner-form"
                    :constrainingFunc="lettersOnlyContrainingFunc"
                />
                <FormInput
                    v-model="partner.commercialContactTitle"
                    class="large block-xs--xs"
                    label="Title"
                    labelSize="partner-form"
                    :constrainingFunc="lettersOnlyContrainingFunc"
                />
                <FormInput
                    v-model="partner.commercialContactEmail"
                    class="large block-xs--xs"
                    label="Email"
                    labelSize="partner-form"
                />
                <FormInput
                    v-model="partner.commercialContactPhone"
                    class="large block-xs--xs"
                    label="Phone"
                    labelSize="partner-form"
                    :constrainingFunc="numberConstrainingFunc"
                />
            </div>
        </LineItem>
        <LineItem
            v-if="partner"
            class="row no-gutters"
        >
            <div class="col-12">
                <FormInput
                    v-model="partner.financialContact"
                    class="large block-xs--xs"
                    label="Financial Contact"
                    labelSize="partner-form"
                    :constraningFunc="lettersOnlyContrainingFunc"
                />
                <FormInput
                    v-model="partner.financialContactTitle"
                    class="large block-xs--xs"
                    label="Title"
                    labelSize="partner-form"
                    :constraningFunc="lettersOnlyContrainingFunc"
                />
                <FormInput
                    v-model="partner.financialContactEmail"
                    class="large block-xs--xs"
                    label="Email"
                    labelSize="partner-form"
                />
                <FormInput
                    v-model="partner.financialContactPhone"
                    class="large block-xs--xs"
                    label="Phone"
                    labelSize="partner-form"
                    :constrainingFunc="numberConstrainingFunc"
                />
            </div>
        </LineItem>
        <LineItem
            v-if="partner"
            class="row no-gutters"
        >
            <div class="col-12">
                <TextBox
                    v-model="partner.specialNotes"
                    rows="6"
                    cols="65"
                    class="block-xs--xs"
                    label="Special Notes"
                    spellcheck="true"
                />
            </div>
        </LineItem>
    </form>

</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import FormInput from 'components/cleverly/FormInput.vue'
import LineItem from 'components/line_item.vue'
import SelectInput from 'components/cleverly/SelectInput.vue'
import DropdownFormInput from 'components/DropdownFormInput.vue'
import TextBox from 'components/cleverly/TextBox.vue'

export default {
    name: 'PartnerForm',
    components: {
        FormInput,
        LineItem,
        SelectInput,
        DropdownFormInput,
        TextBox,
    },
    props: {
        partner: {
            type: Object,
            default: () => {},
        }
    },
    computed: {
        country: {
            get() {
                if (this.partner.country == '') return ''
                return `${this.partner.countryCode.toUpperCase()} - ${this.partner.country}`
            },
            set(newValue) {
                const split = newValue.split(" - ")
                this.partner.countryCode = split[0]
                this.partner.country = split[1]
            }
        },
        partnerOptions() {
            return this.partnerAssignees.map(el => {
                return {
                    text: el,
                    value: el,
                }
            })
        },
        countryOptions() {
            return this.countries.map(el => {
                return {
                    display: `${el.countryCode.toUpperCase()} - ${el.country}`,
                    searchable: el.country
                }
            })
        },
        ...mapGetters('PartnerStore', [
            'partnerAssignees',
            'countries'
        ])
    },
    methods: {
        numberConstrainingFunc(event) {
            const charCode = (event.which) ? event.which : event.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                event.preventDefault()
                return false
            } else {
                return true
            }
        },
        alphanumericConstrainingFunc(event) {
            const charCode = (event.which) ? event.which : event.keyCode;
            if (charCode == 32) return true
            const input = String.fromCharCode(charCode)
            if (!/^[a-z0-9]+$/i.test(input)) {
                event.preventDefault()
                return false
            } else {
                return true
            }
        },
        lettersOnlyContrainingFunc(event) {
            const charCode = (event.which) ? event.which : event.keyCode;
            const input = String.fromCharCode(charCode)
            if(!/^[a-zA-Z]+$/.test(input)) {
                event.preventDefault()
                return false
            } else {
                return true
            }
        }
    }
}
</script>

<style lang="scss">
    @import '~scss/variables';
    @import '~scss/mixins';

    .partner-form {

        textarea {
            @include below(md) {
                width: 100%;
            }
            
        }
        input {
            @include below(xs) {
                width: 100%;
            }
        }

        .form-input {
            input {
                @include above(md) {
                    width: 331px;
                }
            }
        }

        .vselect-input {
            input {
                @include above(md) {
                    width: 305px;
                }

                @include below(xs) {
                    width: 100%;
                }
            }
        }

        .select-input {
            select {
                @include above(md) {
                    width: 331px;
                }
            }
        }

        .block-label > label {
            @include roboto-bold;
            display: block;
            font-size: fz(sm);
            color: $grey_mid_1;
            margin: spacing(xs) 0px;
            width: auto;
        }

        & > .line-item {
            padding: 0px 30px;
            margin-right: 0;
        }

        .label-offset {
            margin-left: 155px;
            &.line-item {
                margin-right: -30px;
                &:before {
                    display: none;
                }
            }
        }
    }
</style>

