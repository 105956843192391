

const requiredFields = {
    'partnerName' : 'Partner Name',
    'partnerShortCode' : 'Partner Short Code',
    'partnerAssignment' : 'Partner Assignment',
    'addressLine1' : 'Address Line 1',
    'city' : 'City',
    'stateProvinceRegion' : 'State/Province/Region',
    'country' : 'Country',
}

const emailFields = {
    'commercialContactEmail' : 'Commercial Contact Email',
    'financialContactEmail' : 'Financial Contact Email',
}

const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export default {
    data() {
        return {
            error: '',
        }
    },
    methods: {
        validateForm() {
            for (const key in requiredFields) {
                if (this.partner[key] === undefined || this.partner[key] === '') {
                    this.error = `Missing Required Field ${requiredFields[key]}.`
                    return false
                }
            }

            for (const key in emailFields) {
                const email = this.partner[key]
                if (!emailRegex.test(email)) {
                    this.error = `${emailFields[key]} is not valid email format.`
                    return false
                }
            }

            this.error = undefined
            return true
        }
    }
}